import React, { useCallback, useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { styled } from "src/stitches"

const LogoAnchor = styled(Link, {
  position: "relative",
  width: "100%",
  paddingTop: "85.5%",
  "&:hover": {
    color: "$accent",
  },
})

const LogoSvgContainer = styled(motion.div, {
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  transition: "transform 0.4s cubic-bezier(0.16, 1, 0.3, 1)",
  transformOrigin: "50% 57.25%",
  variants: {
    hover: {
      true: {
        transform: "rotate(30deg)",
      },
    },
  },
})

export const LinkLogo = React.memo(() => {
  const [hover, setHover] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null)

  const handleClick = useCallback(() => {
    setHover(true)
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setHover(false)
    }, 400)
  }, [setHover])

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef])

  return (
    <LogoAnchor to="/" onClick={handleClick} aria-label="Home" key="logo-link">
      <Circle hover={hover} />
      <Type />
    </LogoAnchor>
  )
})

const Circle = ({ hover }) => (
  <LogoSvgContainer
    key="circle"
    initial="hidden"
    animate="visible"
    hover={hover}
    variants={{
      hidden: {
        clipPath: "inset(0px 0px 100% 0px)",
      },
      visible: {
        clipPath: "inset(0px 0px 0px 0px)",
        transition: {
          type: "tween",
          ease: "circOut",
          duration: 0.6,
          delay: 0.4,
        },
      },
    }}
  >
    <svg viewBox="0 0 542 463" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M271 0C121.573 0 0 121.567 0 270.994c0 71.141 28.877 140.901 79.228 191.395l.417.414 20.697-5.54-1.349-1.254c-51.268-47.71-80.669-115.143-80.669-185.015 0-139.324 113.351-252.673 252.677-252.673 139.324 0 252.673 113.35 252.673 252.673 0 25.289-3.745 50.253-11.13 74.2l-.544 1.763 20.718-5.552.153-.571c6.057-22.641 9.129-46.143 9.129-69.84C542 121.567 420.428 0 271 0"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </LogoSvgContainer>
)

const Type = () => (
  <LogoSvgContainer
    initial="hidden"
    animate="visible"
    key="type"
    variants={{
      hidden: {
        clipPath: "inset(0px 0px 100% 0px)",
      },
      visible: {
        clipPath: "inset(0px 0px 25% 0px)",
        transition: {
          type: "tween",
          ease: "circOut",
          duration: 0.8,
          delay: 0.6,
        },
      },
    }}
  >
    <svg viewBox="0 0 542 463" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M221 293.013h18V215.55l-18 4.869v72.594M229.934 183.875c-6.134 0-10.934 4.763-10.934 10.84 0 6.108 4.699 10.717 10.934 10.717 6.207 0 11.066-4.707 11.066-10.716 0-6.078-4.859-10.84-11.066-10.84M256 145.727v147.286h18V140.857l-18 4.87M430 233.162v-17.75h-25.137v-23.899l-18.178 4.865v19.034H372v17.75h14.685V263.4c0 19.37 9.493 29.613 27.463 29.613H430v-17.061l-14.47-.12c-6.879 0-10.667-4.525-10.667-12.742v-29.928H430M328.014 275.815c-12.523 0-22.712-10.125-22.712-22.577 0-12.447 10.19-22.576 22.712-22.576 12.524 0 22.711 10.13 22.711 22.576 0 12.452-10.187 22.577-22.71 22.577zM328 213.49c-22.057 0-39.999 17.838-39.999 39.76 0 21.927 17.942 39.763 39.999 39.763 22.057 0 40.001-17.836 40.001-39.763 0-21.922-17.944-39.76-40.001-39.76zM166.457 275.815c-11.621 0-21.446-9.124-22.366-20.684l-.004-3.704c.924-11.645 10.749-20.765 22.37-20.765 12.385 0 22.46 10.13 22.46 22.576 0 12.452-10.075 22.577-22.46 22.577zm-.016-62.325c-8.05 0-15.734 2.415-22.35 7v-5.006H126V346.91h18.09v-60.9c6.618 4.584 14.305 7.004 22.351 7.004 21.811 0 39.559-17.836 39.559-39.763 0-21.922-17.748-39.76-39.559-39.76z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </LogoSvgContainer>
)
