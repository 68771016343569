import React, { useMemo } from "react"
import { styled } from "src/stitches"

export const StyledRatio = styled("div", {
  // transform: "translate3d(0,0,0)",
  // backfaceVisibility: "hidden",
  zIndex: 1,
  position: "relative",
  "> * ": {
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    height: "100%",
  },
  variants: {
    ratio: {
      "$1/1": {
        paddingTop: "100%",
      },
      "$16/9": {
        paddingTop: "56.25%",
      },
      "$9/16": {
        paddingTop: "177%",
      },
      "$4/3": {
        paddingTop: "75%",
      },
      "$3/4": {
        paddingTop: "133%",
      },
      "$5/4": {
        paddingTop: "80%",
      },
      "$4/5": {
        paddingTop: "125%",
      }
    },
  },
})

type TRatioProps = {
  ratio: number | "$1/1" | "$16/9" | "$9/16" | "$4/3" | "$3/4" | "$5/4" | "$4/5"
  children: React.ReactNode
}

export const Ratio = React.forwardRef(
  ({ ratio = 1.7777777778, children, ...rest }: TRatioProps, ref: any) => {
    //
    const props = useMemo(() => {
      if (typeof ratio === "string") {
        return { ratio: ratio }
      } else {
        return { css: { paddingTop: `${ratio * 100}%` } }
      }
    }, [ratio])

    return (
      <StyledRatio {...props} {...rest} ref={ref}>
        {children}
      </StyledRatio>
    )
  }
)
