import { styled } from "src/stitches"

export const HeadlineSuperhero = styled("h1", {
  display: "block",
  margin: "0px",
  color: "$mono0",
  fontWeight: "$300",
  fontSize: "45px",
  lineHeight: 1.06,
  whiteSpace: "pre-line",
  paddingTop: "14px",
  paddingBottom: "16px",
  bp1: {
    maxWidth: "13ch",
  },
  bp3: {
    fontSize: "86px",
    lineHeight: 1.04,
    paddingTop: "6px",
    paddingBottom: "19px",
  },
  bp4: {
    fontSize: "90px",
    lineHeight: 1,
    paddingTop: "17px",
    paddingBottom: "31px",
  },
})
