import React, { forwardRef } from "react"
import { styled } from "src/stitches"
import { useIsDark } from "src/context/theme"
import { withDarkTheme } from "src/context/theme/with-dark-theme"

import {
  Container,
  Text,
  Footnote,
  AnchorFooter,
  Grid,
} from "src/components/primitives"
import { Instagram, Linkedin, Vimeo } from "src/components/icons"

const FooterContainer = styled(Container, {
  marginTop: "30px",
  paddingBottom: "26px",

  bp3: {
    marginTop: "30px",
    paddingBottom: "23px",
  },
  bp4: {
    marginTop: "48px",
    paddingBottom: "24px",
  },
})

const StyledFooterGrid = styled(Grid, {
  paddingTop: "35px",
  borderTop: "1px solid $mono10",
  bp3: {
    paddingTop: "42px",
  },
  bp4: {
    paddingTop: "69px",
  },
  variants: {
    dark: {
      true: {
        borderTop: "1px solid $mono70",
      },
    },
  },
})

const FooterGrid = withDarkTheme(StyledFooterGrid)

const IconsContainer = styled("div", {
  display: "flex",
  marginTop: "35px",
  marginBottom: "34px",
  "& > a + a": {
    marginLeft: "30px",
  },
  bp1: {
    justifyContent: "flex-end",
    marginTop: "0px",
    marginBottom: "33px",
  },
  bp3: {
    marginTop: "0px",
    marginBottom: "36px",
  },
  bp4: {
    marginTop: "0px",
    marginBottom: "56px",
  },
})

const FooterIconLink = styled(AnchorFooter, {
  width: "24px",

  bp1: {
    marginTop: "4px",
    marginBottom: "2px",
  },
  bp3: {
    marginTop: "4px",
    marginBottom: "1px",
  },
  bp4: {
    width: "30px",
    marginTop: "0px",
    marginBottom: "0px",
  },
})

export const AppFooter = () => {
  return (
    <>
      <FooterContainer as="footer">
        <FooterGrid columns={{ bp0: "$1", bp1: "$2" }}>
          <p>
            <Text as="span">Get in touch</Text>{" "}
            <AnchorFooter href="mailto:hello@pilotlab.com" target="_blank">
              hello@pilotlab.com
            </AnchorFooter>
          </p>
          <IconsContainer>
            <FooterIconLink
              href="https://www.instagram.com/pilot.lab/"
              aria-label="Instagram"
              rel="noopener"
              target="_blank"
            >
              <Instagram />
            </FooterIconLink>
            <FooterIconLink
              href="https://www.linkedin.com/company/pilot-lab/"
              aria-label="Linkedin"
              rel="noopener"
              target="_blank"
            >
              <Linkedin />
            </FooterIconLink>
            <FooterIconLink
              href="https://vimeo.com/pilotlab"
              aria-label="Vimeo"
              rel="noopener"
              target="_blank"
            >
              <Vimeo />
            </FooterIconLink>
          </IconsContainer>
        </FooterGrid>
        <Footnote>© 2023 Pilot Lab</Footnote>
      </FooterContainer>
    </>
  )
}
