import { styled } from "src/stitches"
import { withDarkTheme } from "src/context/theme/with-dark-theme"

export const StyledHeadline = styled("h2", {
  display: "block",
  whiteSpace: "pre-line",
  margin: "0px",
  fontWeight: "$300",
  fontSize: "30px",
  lineHeight: 1.2,
  paddingTop: "19px",
  paddingBottom: "23px",
  maxWidth: "20ch",
  bp3: {
    fontSize: "44px",
    lineHeight: 1.22,
    maxWidth: "24ch",
    paddingTop: "16px",
    paddingBottom: "20px",
  },
  bp4: {
    fontSize: "48px",
    lineHeight: 1.125,
    paddingTop: "33px",
    paddingBottom: "39px",
  },
  color: "$mono90",
  variants: {
    dark: {
      true: {
        color: "$mono0",
      },
    },
  },
})

export const Headline = withDarkTheme(StyledHeadline)
