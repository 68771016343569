import { styled } from "src/stitches"

export const Block = styled("div", {
  width: "100%",
  paddingTop: "30px",
  paddingBottom: "30px",
  bp3: {
    paddingTop: "36px",
    paddingBottom: "36px",
  },
  bp4: {
    paddingTop: "48px",
    paddingBottom: "48px",
  },
})
