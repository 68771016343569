import React from "react"

import { styled } from "src/stitches"

export const Container = styled("div", {
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  maxWidth: "480px",
  paddingLeft: "20px",
  paddingRight: "20px",
  display: "block",
  bp1: {
    maxWidth: "723px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  bp3: {
    maxWidth: "1496px",
    paddingLeft: "108px",
    paddingRight: "108px",
  },
  bp4: {},
  bp5: {},
  variants: {
    bleed: {
      true: {
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: "100%",
        bp1: {
          maxWidth: "100%",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        bp2: {
          maxWidth: "100%",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        bp3: {
          maxWidth: "1280px",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        bp4: {},
      },
    },
  },
})
