import React from "react"
import { useLocation } from "@reach/router"

import { styled } from "src/stitches"
import { useIsDark } from "src/context/theme"

import { LinkLogo } from "./link-logo"
import { LinkPivot } from "./link-pivot"

const StyledHeader = styled("header", {
  position: "fixed",
  zIndex: 100,
  top: 0,
  width: "100%",
  padding: "30px 20px",
  bp3: {
    padding: "36px",
  },
})

const Nav = styled("nav", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const LogoContainer = styled("div", {
  width: "60px",
  bp3: {
    width: "64px",
  },
  bp4: {
    width: "70px",
  },
  color: "$mono100",
  variants: {
    dark: {
      true: {
        color: "$mono0",
      },
    },
  },
})

const PivotContainer = styled("div", {
  width: "54px",
  height: "54px",
  bp3: {
    width: "58px",
    height: "58px",
  },
  bp4: {
    width: "64px",
    height: "64px",
  },
  color: "$mono50",
  variants: {
    dark: {
      true: {
        color: "$mono50",
      },
    },
  },
})

export const AppHeader: React.FC = () => {
  const { pathname } = useLocation()

  const isDark = useIsDark()

  return (
    <StyledHeader>
      <Nav aria-label="App Nav">
        <LogoContainer dark={isDark}>
          <LinkLogo />
        </LogoContainer>

        <PivotContainer dark={isDark}>
          <LinkPivot state={pathname === "/" ? "home" : "back"} />
        </PivotContainer>
      </Nav>
    </StyledHeader>
  )
}
