import { styled } from "src/stitches"

export const Text = styled("p", {
  margin: "0px",
  fontWeight: "$300",
  fontSize: "18px",
  lineHeight: 1.666,
  bp3: {
    fontSize: "20px",
    lineHeight: 1.5,
  },
  bp4: {
    fontSize: "22px",
    lineHeight: 1.59,
  },
  color: "$mono20",
  variants: {
    dark: {
      true: {
        color: "$mono20",
      },
    },
  },
})
