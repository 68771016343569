import { createStyled } from "@stitches/react"

import { breakpoints } from "./breakpoints"
import { tokens } from "./tokens"

import { global } from "./global"

export const { styled, css } = createStyled({
  breakpoints,
  tokens,
})

export const globalStyles = css.global(global)
