import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import { styled } from "src/stitches"

const StyledLink = styled(Link, {
  "&:hover": {
    color: "$accent",
  },
})

export const LinkPivot = ({ state }) => {
  return (
    <StyledLink
      to={state === "home" ? "/#about" : "/"}
      aria-label={state === "home" ? "About Us" : "Back"}
      className="js-focus-visible"
    >
      <motion.svg
        viewBox="0 0 54 54"
        initial="home"
        animate={state === "home" ? "home" : "back"}
        fill="none"
        strokeMiterlimit={10}
        whileHover={state === "home" ? "homeHover" : "backHover"}
        style={{ overflow: "visible" }}
        variants={{
          home: {},
          homeHover: {},
          back: {},
          backHover: {},
        }}
      >
        <motion.circle
          cx="27"
          cy="27"
          r="26"
          stroke="currentColor"
          strokeWidth="2"
          variants={{
            home: {},
            homeHover: {},
            back: {},
            backHover: {},
          }}
          transition={{
            delay: 0.02,
            duration: 0.6,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
        />
        <motion.path
          initial={false}
          d="M17.5263 27L38.579 27"
          stroke="currentColor"
          strokeWidth={2}
          style={{ transformOrigin: "50% 50%" }}
          transition={{
            duration: 0.6,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
          variants={{
            home: {
              rotate: "-90deg",
              x: "-2%",
            },
            homeHover: {
              rotate: "-50deg",
            },
            back: { rotate: "0deg" },
            backHover: {},
          }}
        />

        <motion.path
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M24.2632 34.1579L16.6842 26.9196L24.2632 19.8421"
          transition={{
            duration: 0.5,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
          variants={{
            home: {
              opacity: 0,
              x: "-2%",
            },
            homeHover: {},
            back: {
              opacity: 1,
              x: "0%",
            },
            backHover: {},
          }}
        />
      </motion.svg>
    </StyledLink>
  )
}
