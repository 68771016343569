import { styled } from "src/stitches"

export const Measure = styled("div", {
  width: "100%",
  variants: {
    measure: {
      $1: {
        maxWidth: "100%",
      },
      "$1/2": {
        maxWidth: "50%",
      },
      "$1/3": {
        maxWidth: "33.333333%",
      },
      "$2/3": {
        maxWidth: "66.666667%",
      },
      "$1/4": {
        maxWidth: "25%",
      },
      "$2/4": {
        maxWidth: "50%",
      },
      "$3/4": {
        maxWidth: "75%",
      },
      "$1/5": {
        maxWidth: "20%",
      },
      "$2/5": {
        maxWidth: "40%",
      },
      "$3/5": {
        maxWidth: "60%",
      },
      "$4/5": {
        maxWidth: "80%",
      },
      "$1/6": {
        maxWidth: "16.666667%",
      },
      "$2/6": {
        maxWidth: "33.333333%",
      },
      "$3/6": {
        maxWidth: "50%",
      },
      "$4/6": {
        maxWidth: "66.666667%",
      },
      "$5/6": {
        maxWidth: "83.333333%",
      },
      "$1/7": {
        maxWidth: "14.285714%",
      },
      "$2/7": {
        maxWidth: "28.571428%",
      },
      "$3/7": {
        maxWidth: "42.857142%",
      },
      "$4/7": {
        maxWidth: "57.142857%",
      },
      "$5/7": {
        maxWidth: "71.428571%",
      },
      "$6/7": {
        maxWidth: "85.714285%",
      },
    },
  },
})
