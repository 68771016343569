export const tokens = {
  fonts: {
    $sans:
      'MarkOT, system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
  },
  fontWeights: {
    $300: "300",
  },

  colors: {
    $transparent: "transparent",
    $current: "currentColor",
    $mono100: "#000000",
    $mono90: "#1D1C1C",
    $mono70: "#404040",
    $mono50: "#6A6A6A",
    $mono20: "#757575",
    $mono10: "#d9d9d9",
    $mono0: "#FFFFFF",
    $accent: "#F5DD01",
  },
}
