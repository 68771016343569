import { Link } from "gatsby"
import { styled } from "src/stitches"
import { withDarkTheme } from "src/context/theme/with-dark-theme"

export const StyledAnchor = styled("a", {
  position: "relative",
  margin: "0px",
  fontWeight: "$300",
  fontSize: "18px",
  lineHeight: 1.666,

  bp3: {
    fontSize: "20px",
    lineHeight: 1.5,
  },
  bp4: {
    fontSize: "22px",
    lineHeight: 1.59,
  },
  "&:hover": {
    color: "$accent",
  },
  "&:after": {
    content: '""',
    width: "100%",
    height: "1px",
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "$current",
  },
  color: "$mono20",
  variants: {
    dark: {
      true: {
        color: "$mono50",
      },
    },
  },
})

export const Anchor = withDarkTheme(StyledAnchor)

export const StyledAnchorFooter = styled("a", {
  position: "relative",
  margin: "0px",
  fontWeight: "$300",
  fontSize: "18px",
  lineHeight: 1.666,

  bp3: {
    fontSize: "20px",
    lineHeight: 1.5,
  },
  bp4: {
    fontSize: "22px",
    lineHeight: 1.59,
  },
  color: "$mono100",
  variants: {
    dark: {
      true: {
        color: "$mono0",
      },
    },
  },
})

export const AnchorFooter = withDarkTheme(StyledAnchorFooter)
