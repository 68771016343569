import React from "react"

export const Instagram = props => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 2.703c4.005 0 4.48.015 6.061.087 1.463.067 2.257.311 2.786.517.7.272 1.2.597 1.724 1.122.525.525.85 1.024 1.122 1.724.206.53.45 1.323.517 2.786.072 1.582.087 2.056.087 6.061s-.015 4.48-.087 6.061c-.067 1.463-.311 2.257-.517 2.786-.272.7-.597 1.2-1.122 1.724a4.648 4.648 0 01-1.724 1.122c-.529.206-1.323.45-2.786.517-1.581.072-2.056.087-6.061.087s-4.48-.015-6.061-.087c-1.463-.067-2.257-.311-2.786-.517-.7-.272-1.2-.597-1.724-1.122a4.647 4.647 0 01-1.122-1.724c-.206-.529-.45-1.323-.517-2.786-.072-1.581-.087-2.056-.087-6.061s.015-4.48.087-6.061c.067-1.463.311-2.257.517-2.786.272-.7.597-1.2 1.122-1.724a4.648 4.648 0 011.724-1.122c.529-.206 1.323-.45 2.786-.517 1.581-.072 2.056-.087 6.061-.087zM15 0c-4.074 0-4.585.017-6.184.09-1.597.073-2.687.327-3.642.698a7.353 7.353 0 00-2.656 1.73 7.353 7.353 0 00-1.73 2.656C.416 6.13.162 7.22.09 8.816.017 10.416 0 10.926 0 15s.017 4.585.09 6.184c.073 1.597.327 2.688.697 3.642a7.353 7.353 0 001.73 2.656 7.352 7.352 0 002.657 1.73c.955.371 2.045.625 3.642.698 1.6.073 2.11.09 6.184.09s4.585-.017 6.184-.09c1.597-.073 2.687-.327 3.642-.698a7.352 7.352 0 002.656-1.73 7.352 7.352 0 001.73-2.656c.371-.954.625-2.045.698-3.642.073-1.6.09-2.11.09-6.184s-.017-4.585-.09-6.184c-.073-1.597-.327-2.687-.698-3.642a7.352 7.352 0 00-1.73-2.656 7.353 7.353 0 00-2.656-1.73C23.87.417 22.78.163 21.184.09 19.584.017 19.074 0 15 0zm0 7.297a7.703 7.703 0 100 15.406 7.703 7.703 0 000-15.406zM15 20a5 5 0 110-10 5 5 0 010 10zm9.807-13.007a1.8 1.8 0 11-3.6 0 1.8 1.8 0 013.6 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Linkedin = props => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.75 0H6.25A6.25 6.25 0 000 6.25v17.5A6.25 6.25 0 006.25 30h17.5A6.25 6.25 0 0030 23.75V6.25A6.25 6.25 0 0023.75 0zM10 23.75H6.25V10H10v13.75zM8.125 8.415A2.197 2.197 0 015.937 6.21c0-1.218.98-2.205 2.188-2.205 1.207 0 2.188.987 2.188 2.205a2.196 2.196 0 01-2.188 2.205zM25 23.75h-3.75v-7.005c0-4.21-5-3.891-5 0v7.005H12.5V10h3.75v2.206C17.995 8.974 25 8.735 25 15.301v8.449z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Vimeo = props => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h30v30H0V0zm20.822 18.627c2.851-3.668 4.325-6.557 4.421-8.666.129-2.823-.921-4.268-3.148-4.334-3.005-.098-5.04 1.598-6.106 5.087a4.06 4.06 0 011.604-.356c1.103.001 1.59.62 1.46 1.857-.064.748-.55 1.84-1.46 3.273-.909 1.434-1.59 2.15-2.044 2.15-.584.002-1.12-1.103-1.607-3.313-.163-.649-.455-2.305-.876-4.968-.389-2.466-1.428-3.619-3.115-3.457-.714.065-1.785.714-3.213 1.947-1.04.943-2.094 1.883-3.164 2.82l1.022 1.315c.97-.681 1.537-1.022 1.7-1.022.743.001 1.439 1.167 2.088 3.5l1.75 6.416c.874 2.332 1.943 3.499 3.207 3.499 2.04 0 4.533-1.916 7.48-5.748z"
        fill="currentColor"
      />
    </svg>
  )
}
