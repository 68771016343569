export const global = {
  body: {
    margin: "0",
    fontFamily: "$sans",
    "&.body.theme-dark": {
      backgroundColor: "$mono100",
    },
    "&.theme-light": {
      backgroundColor: "$mono0",
    },
  },
  a: {
    display: "inline-block",
    color: "inherit",
    textDecoration: "none",
    position: "relative",
    "&:hover": {
      color: "$accent",
    },
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    display: "inline-block",
    color: "inherit",
    textDecoration: "none",
    margin: 0,
    padding: 0,
    appearance: "none",
    background: "transparent",
    textAlign: "left",
    "&:focus": {
      outline: "none",
    },
  },
}
