import { styled } from "src/stitches"

export const Footnote = styled("p", {
  margin: "0px",
  fontWeight: "$300",
  fontSize: "12px",
  lineHeight: 1.5,
  bp3: {
    fontSize: "14px",
    lineHeight: 1.71,
  },
  bp4: {
    fontSize: "16px",
    lineHeight: 2.25,
  },
  color: "$mono20",
})
