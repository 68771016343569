import { styled } from "src/stitches"

export const HeadlineSecondary = styled("p", {
  margin: "0px",
  fontWeight: "$300",
  fontSize: "24px",
  lineHeight: 1.25,
  color: "$mono50",
  maxWidth: "20ch",
  paddingTop: "12px",
  paddingBottom: "16px",
  whiteSpace: "pre-line",
  bp3: {
    maxWidth: "24ch",
    fontSize: "32px",
    lineHeight: 1.21875,
    paddingTop: "16px",
    paddingBottom: "20px",
  },
  bp4: {
    fontSize: "36px",
    lineHeight: 1.166,
    paddingTop: "31px",
    paddingBottom: "35px",
  },
  "& > span": {
    display: "block",
    color: "$mono0",
  },
})
