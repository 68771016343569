import { styled } from "src/stitches"
import { Text } from "src/components/primitives"

export const Paragraph = styled(Text, {
  paddingTop: "20px",
  paddingBottom: "22px",
  bp1: {
    maxWidth: "40ch",
  },
  bp3: {
    paddingTop: "25px",
    paddingBottom: "29px",
    fontSize: "20px",
    lineHeight: 1.5,
    maxWidth: "42ch",
  },
  bp4: {
    paddingTop: "40px",
    paddingBottom: "44px",
    fontSize: "22px",
    lineHeight: 1.59,
  },
})

export const ParagraphTall = styled(Text, {
  paddingTop: "52px",
  paddingBottom: "54px",
  bp1: {
    maxWidth: "40ch",
  },
  bp3: {
    paddingTop: "65px",
    paddingBottom: "69px",
    fontSize: "20px",
    lineHeight: 1.5,
    maxWidth: "42ch",
  },
  bp4: {
    paddingTop: "88px",
    paddingBottom: "92",
    fontSize: "22px",
    lineHeight: 1.59,
  },
})
