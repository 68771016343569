module.exports = [{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"SEA-LON-23","partialMatching":false,"pagePaths":["/case-studies/spotify-live/","/case-studies/spotify-supremium/","/case-studies/google-superformat/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pilot Lab","short_name":"pilot","start_url":"/","lang":"en","display":"standalone","icon":"src/images/pilot-icon.png","background_color":"#000","theme_color":"#FFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"704a04c4699cfb7ef25815f41383e091"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
