import { styled } from "src/stitches"
import { withDarkTheme } from "src/context/theme/with-dark-theme"

export const StyledHorizontalRuler = styled("hr", {
  display: "block",
  maxWidth: "100%",
  border: "none",
  borderBottom: "none",
  margin: 0,
  marginTop: "30px",
  marginBottom: "29px",
  bp3: {
    marginTop: "30px",
    marginBottom: "29px",
  },
  bp4: {
    marginTop: "48px",
    marginBottom: "47px",
  },
  borderTop: "1px solid $mono10",
  variants: {
    dark: {
      true: {
        borderTop: "1px solid $mono70",
      },
    },
  },
})

export const HorizontalRuler = withDarkTheme(StyledHorizontalRuler)
