import { styled } from "src/stitches"

export const PageBg = styled("div", {
  backgroundColor: "$mono0",
  transition: "background-color 1s cubic-bezier(0.16, 1, 0.3, 1)",
  variants: {
    dark: {
      true: {
        backgroundColor: "$mono100",
      },
    },
  },
})
