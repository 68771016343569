import React, { useEffect, useRef } from "react"
import { styled } from "src/stitches"
import { motion, AnimatePresence } from "framer-motion"

type TExpandableProps = {
  expanded: boolean
  children: React.ReactNode
}

const ExpandableContents = styled(motion.div, {})

export const Expandable = (props: TExpandableProps) => {
  const { expanded, ...rest } = props

  return (
    <AnimatePresence initial={false}>
      {expanded && (
        <ExpandableContents
          {...rest}
          initial="collapsed"
          animate="expanded"
          exit="collapsed"
          style={{ overflow: "hidden" }}
          variants={{
            expanded: { height: "auto", opacity: 1 },
            collapsed: { height: 0, opacity: 0 },
          }}
          transition={{
            duration: 0.7,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
        >
          {props.children}
        </ExpandableContents>
      )}
    </AnimatePresence>
  )
}
