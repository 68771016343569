import { styled } from "src/stitches"

export const Grid = styled("div", {
  display: "grid",
  width: "100%",
  columnGap: "0px",
  rowGap: "0px",
  bp3: {
    columnGap: "0px",
  },
  variants: {
    gap: {
      true: {
        columnGap: "16px",
        rowGap: "24px",
        bp3: {
          columnGap: "24px",
        },
      },
    },
    columns: {
      $1: {
        gridTemplateColumns: "repeat(1,minmax(0,1fr))",
      },
      $2: {
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
      },
      $3: {
        gridTemplateColumns: "repeat(3,minmax(0,1fr))",
      },
      $4: {
        gridTemplateColumns: "repeat(4,minmax(0,1fr))",
      },
      $5: {
        gridTemplateColumns: "repeat(5,minmax(0,1fr))",
      },
      $6: {
        gridTemplateColumns: "repeat(6,minmax(0,1fr))",
      },
      $7: {
        gridTemplateColumns: "repeat(7,minmax(0,1fr))",
      },
      $8: {
        gridTemplateColumns: "repeat(8,minmax(0,1fr))",
      },
      $9: {
        gridTemplateColumns: "repeat(9,minmax(0,1fr))",
      },
      $10: {
        gridTemplateColumns: "repeat(§0,minmax(0,1fr))",
      },
      $11: {
        gridTemplateColumns: "repeat(11,minmax(0,1fr))",
      },
      $12: {
        gridTemplateColumns: "repeat(12,minmax(0,1fr))",
      },
    },
  },
})

export const Col = styled("div", {
  display: "grid",
  alignItems: "start",
  position: "relative",
  variants: {
    align: {
      $start: {
        alignItems: "start",
      },
      $end: {
        alignItems: "end",
      },
      $stretch: {
        alignItems: "stretch",
      },
    },
    justify: {
      $start: {
        justifyItems: "start",
      },
      $end: {
        justifyItems: "end",
      },
      $stretch: {
        justifyItems: "stretch",
      },
    },
    start: {
      $1: {
        gridColumnStart: 1,
      },
      $2: {
        gridColumnStart: 2,
      },
      $3: {
        gridColumnStart: 3,
      },
      $4: {
        gridColumnStart: 4,
      },
      $5: {
        gridColumnStart: 5,
      },
      $6: {
        gridColumnStart: 6,
      },
      $7: {
        gridColumnStart: 7,
      },
      $8: {
        gridColumnStart: 8,
      },
      $9: {
        gridColumnStart: 9,
      },
      $10: {
        gridColumnStart: 10,
      },
      $11: {
        gridColumnStart: 11,
      },
      $12: {
        gridColumnStart: 12,
      },
    },
    end: {
      $1: {
        gridColumnEnd: 1,
      },
      $2: {
        gridColumnEnd: 2,
      },
      $3: {
        gridColumnEnd: 3,
      },
      $4: {
        gridColumnEnd: 4,
      },
      $5: {
        gridColumnEnd: 5,
      },
      $6: {
        gridColumnEnd: 6,
      },
      $7: {
        gridColumnEnd: 7,
      },
      $8: {
        gridColumnEnd: 8,
      },
      $9: {
        gridColumnEnd: 9,
      },
      $10: {
        gridColumnEnd: 10,
      },
      $11: {
        gridColumnEnd: 11,
      },
      $12: {
        gridColumnEnd: 12,
      },
      $13: {
        gridColumnEnd: 13,
      },
    },
  },
})

export const Cell = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "36px",
  bp1: {
    padding: "48px",
  },
  bp3: {
    padding: "42px",
  },
  variants: {
    align: {
      $start: {
        alignItems: "flex-start",
      },
      $center: {
        alignItems: "center",
      },
      $end: {
        alignItems: "flex-end",
      },
    },
    justify: {
      $start: {
        justifyContent: "flex-start",
      },
      $center: {
        justifyContent: "center",
      },
      $end: {
        justifyContent: "flex-end",
      },
    },
    size: {
      $large: {
        padding: "24px",
        bp1: {
          padding: "36px",
        },
        bp3: {
          padding: "54px",
        },
        bp4: {
          padding: "66px",
        },
      },
    },
  },
})

export const CellVerticalOffset = styled("div", {
  display: "none",
  bp3: {
    display: "block",
    height: "168px",
  },
  bp4: {
    height: "252px",
  },
  bp6: {
    height: "336px",
  },
})
