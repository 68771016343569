export const breakpoints = {
  bp0: function (rule) {
    return rule
  },
  bp1: function (rule) {
    return "@media (min-width: 640px) { " + rule + " }"
  },
  bp2: function (rule) {
    return "@media (min-width: 724px) { " + rule + " }"
  },
  bp3: function (rule) {
    return "@media (min-width: 1024px) { " + rule + " }"
  },
  bp4: function (rule) {
    return "@media (min-width: 1280px) { " + rule + " }"
  },
  bp5: function (rule) {
    return "@media (min-width: 1920px) { " + rule + " }"
  },
  motion: function (rule) {
    return "@media (prefers-reduced-motion) { " + rule + " }"
  },
  hover: function (rule) {
    return "@media (hover: hover) { " + rule + " }"
  },
}
