import React from "react"

import { globalStyles } from "src/stitches"
import { AppHeader } from "src/components/app-header"
import { AppFooter } from "src/components/app-footer"
import { ThemeProvider } from "src/context/theme"

export const wrapPageElement = ({ element }) => {
  globalStyles()

  return (
    <ThemeProvider>
      <AppHeader />
      <main>{element}</main>
      <AppFooter />
    </ThemeProvider>
  )
}
