import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react"

export const ThemeContext = createContext({
  isDark: false,
  setDarkTheme: (dark: any) => null,
})

export const useDarkTheme = () => {
  const { setDarkTheme } = useContext(ThemeContext)

  useEffect(() => {
    setDarkTheme(true)
  }, [setDarkTheme])
}

export const useLightTheme = () => {
  const { setDarkTheme } = useContext(ThemeContext)

  useEffect(() => {
    setDarkTheme(false)
  }, [setDarkTheme])
}

export const useIsDark = () => {
  const { isDark } = useContext(ThemeContext)
  return isDark
}

export const ThemeProvider = ({ children }) => {
  const [isDark, setDarkTheme] = useState(false)

  const providerValue = useMemo(() => {
    return { isDark, setDarkTheme }
  }, [isDark, setDarkTheme])

  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  )
}
