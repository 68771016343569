/**
 * Write-only the password as cookie
 */
 import React, { useState } from 'react';
 import { setSessionPassword } from '../utils/utils';
 
 const styles = {
   wrapper: {
     height: '100vh',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'center',
     alignItems: 'center',
   },
   input: {
     width: '100%',
     height: '48px',
     background: "#eeeeee",
     textAlign: 'center'
   },
   button: {
     width: '100%',
     height: '48px',
     background: '#000000',
     color: '#ffffff',
     margin: '20px auto',
     textAlign: 'center'

   },
   buttonHover: {
     background: '#F5DD01',
     color: '#000000'
   },
 };
 
 const PasswordProtect = () => {
   const [password, setPassword] = useState('');
   const [isButtonHovered, buttonHover] = useState(false);
 
   const onSubmit = event => {
     event.preventDefault();
     setSessionPassword(password);
     window.location.reload(); // eslint-disable-line
   };
 
   return (
     <div style={styles.wrapper}>
 
       <form onSubmit={onSubmit} style={{ width: '320px' }}>
         <input
           name="password"
           type="password"
           placeholder='Password'
           value={password}
           onChange={event => setPassword(event.target.value)}
           style={styles.input}
         />
 
         <button
           type="submit"
           style={{
             ...styles.button,
             ...(isButtonHovered ? styles.buttonHover : null)
           }}
           onMouseEnter={() => buttonHover(true)}
           onMouseLeave={() => buttonHover(false)}
         >
           Enter
         </button>
       </form>
 
       
     </div>
   );
 };
 
 export default PasswordProtect;
 